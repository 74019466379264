import React from 'react'
import { observer } from 'mobx-react-lite'
import { NextPaymentWidget } from '../../components/NextPaymentWidget'
import { GetCreditLinesMFOModel } from '@/api/products/mfo/getCreditLinesMFO'

export interface NextPaymentProps {
  contract: GetCreditLinesMFOModel.CreditLine
  serverDate: string
}

export const NextPayment = observer(({ contract, serverDate }: NextPaymentProps) => {
  const { debtDays, guiStatus, status, nearestPayment, nearestDate } = contract

  return (
    <NextPaymentWidget
      debtDays={debtDays}
      guiStatus={guiStatus}
      status={status}
      nearestDate={nearestDate}
      nearestPayment={nearestPayment}
      serverDate={serverDate}
    />
  )
})
