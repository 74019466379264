import styled from '@emotion/styled'
import { ButtonBase } from '@platform-ui/components/ButtonBase'

export const Title = styled.span(({ theme }) => ({
  ...theme.typography['headline/16'],
  marginTop: 8,
  fontSize: 12,
  lineHeight: '16px',
  color: theme.color[`text/main`],
}))

export const Wrapper = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  ['& > div']: {
    width: 28,
    height: 28,
  },

  [`${theme.media.md}`]: {
    ['& > div']: {
      width: 32,
      height: 32,
    },
  },
}))

export const PaymentWrapper = styled(ButtonBase)(({ theme }) => ({
  backgroundColor: theme.color['surface/tertiary'],
  borderRadius: 16,
  padding: 16,
  marginBottom: 24,
  width: '100%',

  [`${theme.media.md}`]: {
    padding: '24px 16px',
    borderRadius: 20,
    marginBottom: 40,
  },
}))
