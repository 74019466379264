import styled from '@emotion/styled'
import { Shimmer } from '@platform-ui/components/Shimmer'

export const PaymentShimmerWrapper = styled.div(({ theme }) => {
  return {
    backgroundColor: theme.color['surface/tertiary'],
    borderRadius: 12,
    padding: 16,
    marginTop: 24,

    [`${theme.media.md}`]: {
      marginTop: 40,
      padding: 24,
    },
  }
})

export const PaymentTitleShimmer = styled(Shimmer)(({ theme }) => {
  return {
    borderRadius: 12,
    height: 24,
    width: 200,
    marginBottom: 16,

    [`${theme.media.md}`]: {
      width: 280,
      marginBottom: 24,
    },
  }
})

export const PaymentDateTitleShimmer = styled(Shimmer)(({ theme }) => {
  return {
    borderRadius: 6,
    height: 12,
    width: 100,
    marginBottom: 8,

    [`${theme.media.md}`]: {
      width: 96,
    },
  }
})

export const PaymentDateDescShimmer = styled(Shimmer)(({ theme }) => {
  return {
    borderRadius: 10,
    height: 20,
    width: 200,

    [`${theme.media.md}`]: {
      borderRadius: 12,
      height: 24,
      width: 250,
    },
  }
})

export const PaymentWidgetShimmer = styled.div(({ theme }) => {
  return {
    borderRadius: 12,
    backgroundColor: theme.color['surface/tertiary'],
    padding: 16,
    marginTop: 16,
    marginBottom: 24,

    [`${theme.media.md}`]: {
      padding: 24,
      marginBottom: 40,
    },
  }
})

export const PaymentWidgetDescShimmer = styled(Shimmer)(() => ({
  borderRadius: 6,
  height: 12,
  width: 100,
  marginBottom: 8,
}))

export const PaymentWidgetTitleShimmer = styled(Shimmer)(({ theme }) => ({
  borderRadius: 12,
  height: 24,
  width: 110,
  marginBottom: 20,

  [`${theme.media.md}`]: {
    marginBottom: 24,
  },
}))

export const PaymentWidgetButtonShimmer = styled(Shimmer)(() => ({
  borderRadius: 12,
  height: 56,
}))
