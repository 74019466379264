import styled from '@emotion/styled'
import { Shimmer } from '@platform-ui/components/Shimmer'

export const Wrapper = styled.div(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}))

export const ShimmerWrapper = styled(Shimmer)(({ theme }) => ({
  borderRadius: 16,
  width: '100%',
  margin: '32px auto 46px',
  padding: 16,
  background: theme.color['surface/tertiary'],

  [theme.media.md]: {
    padding: 32,
  },
}))

export const SliderBalanceShimmer = styled(Shimmer)(({ theme }) => ({
  borderRadius: 16,
  height: 44,
  width: 193,
  background: theme.color['surface/bravo'],

  [theme.media.md]: {
    borderRadius: 20,
    height: 40,
    width: 249,
  },
}))

export const SliderPartnerShimmer = styled(Shimmer)(({ theme }) => ({
  background: theme.color['surface/bravo'],
  borderRadius: 8,
  height: 44,
  width: 150,
}))

export const DescriptionShimmerWrapper = styled.div(({ theme }) => ({
  marginTop: theme.spacing.s12,
}))

export const ItemWrapper = styled.div(({ theme }) => ({ marginTop: theme.spacing.s6 }))

export const LabelShimmer = styled(Shimmer)(({ theme }) => ({
  width: 150,
  height: 14,
  marginBottom: theme.spacing.s4,
  borderRadius: 8,
}))

export const AmountShimmer = styled(Shimmer)(() => ({
  width: 150,
  height: 16,
  borderRadius: 8,
}))
