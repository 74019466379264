import React, { FC } from 'react'
import { DepositSecondaryIcon } from '@platform-ui/icons'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { PaymentWrapper, Title, Wrapper } from './styled'

type PaymentPlateProps = {
  onOpen: () => void
}

export const PaymentPlate: FC<PaymentPlateProps> = ({ onOpen }) => {
  const { config } = useAppContext<AppContext>()
  const isMobile = config.device.isMobile

  return (
    <PaymentWrapper onClick={onOpen}>
      <Wrapper>
        <DepositSecondaryIcon color={'icons/primary'} size={isMobile ? 's12' : 's16'} />
        <Title>Пополнить</Title>
      </Wrapper>
    </PaymentWrapper>
  )
}
