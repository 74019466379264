import React, { useState, FC } from 'react'
import { PaymentPlate } from '../../components/PaymentPlate'
import { PaymentModal } from '@/features/product-lists/containers/ProductListMFO/PaymentModal'
import { GetCreditLinesMFOModel } from '@/api/products/mfo/getCreditLinesMFO'

export const Payment: FC<{
  contract: GetCreditLinesMFOModel.CreditLine
  refetch: () => void
}> = ({ contract, refetch }) => {
  const [isOpen, setIsOpen] = useState(false)

  const { nearestPayment, type } = contract

  return (
    <>
      <PaymentPlate
        onOpen={() => {
          setIsOpen(true)
        }}
      />
      {isOpen && (
        <PaymentModal
          contractId={contract.contractId}
          amount={nearestPayment}
          isOpen={isOpen}
          onClose={() => {
            refetch()
            setIsOpen(false)
          }}
          contractType={type}
          recipient={contract?.partnerInfo?.PartnerName || ''}
        />
      )}
    </>
  )
}
