import React from 'react'
import {
  PaymentShimmerIcon,
  PaymentShimmerItem,
  PaymentShimmerText,
  PaymentShimmerWrapper,
} from './styled'

export const PaymentShimmer = () => {
  return (
    <PaymentShimmerWrapper>
      <PaymentShimmerItem>
        <PaymentShimmerIcon />
        <PaymentShimmerText />
      </PaymentShimmerItem>
    </PaymentShimmerWrapper>
  )
}
