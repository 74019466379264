import { Currency } from '@/components/Currency'
import styled from '@emotion/styled'
import { Body14, Headline32 } from '@platform-ui/typography'

export const SliderWrapper = styled.div(({ theme }) => ({
  marginTop: 16,
  marginBottom: 24,

  [theme.media.md]: {
    marginTop: 32,
    marginBottom: 40,
  },
}))

export const Slider = styled.div<{ bgColor: string }>(({ theme, bgColor }) => ({
  borderRadius: theme.borderRadius.r14,
  background: theme.color[bgColor] || bgColor,
  height: 200,
  maxWidth: 656,
  padding: 16,
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',

  [theme.media.md]: {
    height: 234,
    padding: 32,
  },
}))

export const SliderTitle = styled(Headline32)(({ theme }) => ({
  fontWeight: 700,
  color: theme.color['text/tertiary'],

  [theme.media.md]: {
    fontSize: 40,
    lineHeight: '48px',
    letterSpacing: '-0.6px',
  },
}))

export const SliderDescription = styled(Body14)(({ theme }) => ({
  color: theme.color['text/tertiaryTransparent'],
  marginTop: 8,
}))

export const SliderDescriptionItem = styled.div(({ theme }) => ({
  marginTop: theme.spacing.s6,
}))

export const Amount = styled(Currency)(({ theme }) => ({
  ...theme.typography['headline/16'],
}))

export const PartnerIcon = styled.img(({ theme }) => ({
  height: 12,

  [theme.media.md]: {
    height: 24,
  },
}))

export const InfoWrapper = styled.div(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 8,
}))
