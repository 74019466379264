import styled from '@emotion/styled'
import { Shimmer } from '@platform-ui/components/Shimmer'

export const PaymentShimmerWrapper = styled.div(({ theme }) => {
  return {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.color['surface/tertiary'],
    borderRadius: 16,
    height: 84,
    padding: 16,
    margin: '24px 0',

    [`${theme.media.md}`]: {
      margin: '40px 0',
      padding: 24,
      borderRadius: 20,
      height: 112,
    },
  }
})

export const PaymentShimmerItem = styled.div(() => {
  return {
    maxWidth: 240,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  }
})

export const PaymentShimmerIcon = styled(Shimmer)(({ theme }) => {
  return {
    borderRadius: 14,
    height: 28,
    width: 28,

    [`${theme.media.md}`]: {
      borderRadius: 16,
      height: 32,
      width: 32,
    },
  }
})

export const PaymentShimmerText = styled(Shimmer)(({ theme }) => {
  return {
    borderRadius: 10,
    height: 12,
    width: 56,
    marginTop: 8,

    [`${theme.media.md}`]: {
      borderRadius: 28,
      width: 104,
      height: 16,
      marginTop: 16,
    },
  }
})
