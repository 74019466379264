import React from 'react'
import {
  PaymentShimmerWrapper,
  PaymentDateDescShimmer,
  PaymentDateTitleShimmer,
  PaymentTitleShimmer,
  PaymentWidgetButtonShimmer,
  PaymentWidgetDescShimmer,
  PaymentWidgetShimmer,
  PaymentWidgetTitleShimmer,
} from './styled'

export const NextPaymentShimmer = () => {
  return (
    <>
      <PaymentShimmerWrapper>
        <div>
          <PaymentTitleShimmer />
          <PaymentDateTitleShimmer />
          <PaymentDateDescShimmer />
        </div>
      </PaymentShimmerWrapper>

      <PaymentWidgetShimmer>
        <div>
          <PaymentWidgetDescShimmer />
          <PaymentWidgetTitleShimmer />
        </div>

        <PaymentWidgetButtonShimmer />
      </PaymentWidgetShimmer>
    </>
  )
}
