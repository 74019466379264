import React, { useEffect } from 'react'
import { useRouter } from '@/next/router'
import { ContentHeader } from '@/components/ContentHeader'
import { ContentError } from '@/components/ContentError'
import { ApiStatus, useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { useSettingsQuery } from '@/api/settings/settings'
import { ResponseType } from '@/models/ResponseType'
import { useClientProductsMFOSelector } from './selectors/useClientProductsMFOSelector'
import { CreditLineDetails } from '@/features/credit-line-details'
import { CreditLineSlider } from '@/features/credit-line-slider'
import { ClientProductsMFOModel } from '@/api/products/clientProductsMFO'
import { useGetCreditLinesMFOQuery } from '@/api/products/mfo/getCreditLinesMFO'

export const CreditLinePage = () => {
  const router = useRouter()
  const context = useAppContext<AppContext>()
  const productId = router.query.productId as string

  const { data: settingsData, status: settingsStatus } = useSettingsQuery()
  const { status: productsStatus, contracts: products } = useClientProductsMFOSelector()

  const enableProductsMfo =
    settingsData &&
    settingsData.type === ResponseType.ResolveWithData &&
    settingsData.result.enableProductsMfo

  const creditLineContract = products[productId]
  const isCreditLineNotSet = creditLineContract?.status === ClientProductsMFOModel.StatusCode.NotSet

  const {
    data: creditLineData,
    status: creditLineStatus,
    refetch,
  } = useGetCreditLinesMFOQuery({
    enabled: !!creditLineContract && !isCreditLineNotSet,
  })

  const isLoading =
    productsStatus === ApiStatus.Pending ||
    creditLineStatus === 'loading' ||
    settingsStatus === 'loading'
  const isServerError = productsStatus === ApiStatus.Rejected || creditLineStatus === 'error'
  const isProductNotFound = productsStatus === ApiStatus.Fulfilled && !products[productId]

  useEffect(() => {
    if (!isLoading && (!enableProductsMfo || isCreditLineNotSet)) {
      router.push(context.routes.home())
    }
  }, [enableProductsMfo, settingsData, router, context.routes, isCreditLineNotSet, isLoading])

  if ((!creditLineContract && !isLoading) || isServerError || isProductNotFound) {
    return <ContentError />
  }

  const creditLine =
    creditLineData?.type === ResponseType.ResolveWithData
      ? creditLineData?.creditLinesInfo?.find(
          (line) => line?.contractId === creditLineContract?.contractId
        )
      : null

  const title = creditLine?.partnerInfo?.partnerName || ''
  const description = creditLine?.type || 'Кредитная линия'
  const serverDate =
    creditLineData?.type === ResponseType.ResolveWithData ? creditLineData.serverDate : ''

  if (creditLine) {
    return (
      <>
        <ContentHeader isLoading={isLoading} title={title} description={description} />
        <CreditLineSlider contract={creditLine} isLoading={isLoading} />
        <CreditLineDetails
          refetch={refetch}
          contract={creditLine}
          serverDate={serverDate}
          isLoading={isLoading}
        />
      </>
    )
  }

  return null
}
