import { ResponseType } from '@/models/ResponseType'
import { createSelector } from 'reselect'
import { UseQueryResult } from 'react-query'
import { SystemError } from '@/models/SystemError'
import { ApiStatus } from '@/core'
import _keyBy from 'lodash/keyBy'
import { ClientProductsMFOModel, useClientProductsMFOQuery } from '@/api/products/clientProductsMFO'

interface State {
  clientProductsMFO: UseQueryResult<
    ClientProductsMFOModel.ResponseData,
    ClientProductsMFOModel.ErrorResponse
  >
}

const selector = createSelector(
  (state: State) => state.clientProductsMFO.data,
  (state: State) => state.clientProductsMFO.status,
  (state: State) => state.clientProductsMFO.refetch,
  (
    clientProductsResponse,
    clientProductsStatus,
    clientProductsRefetch
  ): {
    error: SystemError<'NETWORK' | 'IS_EMPTY_PRODUCTS'> | null
    status: ApiStatus
    retry: () => void
    contracts: Record<string, ClientProductsMFOModel.CreditLine>
  } => {
    const retryFns: (() => void)[] = []

    let contracts: ClientProductsMFOModel.CreditLine[] = []

    let hasNetworkError = false
    let status = ApiStatus.Idle

    if (clientProductsStatus === 'success' || clientProductsStatus === 'error') {
      status = ApiStatus.Fulfilled
      retryFns.push(clientProductsRefetch)
    }

    if (clientProductsStatus === 'loading') {
      status = ApiStatus.Pending
    }

    if (clientProductsStatus === 'error') {
      hasNetworkError = true
    }

    if (clientProductsResponse && clientProductsResponse.type === ResponseType.ResolveWithData) {
      contracts = clientProductsResponse.CreditLines.filter(
        (product) => product.status !== ClientProductsMFOModel.StatusCode.NotSet
      ) as ClientProductsMFOModel.CreditLine[]
    }

    const error: SystemError<'NETWORK' | 'IS_EMPTY_PRODUCTS'> | null = hasNetworkError
      ? {
          type: 'NETWORK',
          message: 'При получении данных произошла ошибка',
        }
      : status === ApiStatus.Fulfilled && !contracts.length
      ? {
          type: 'IS_EMPTY_PRODUCTS',
          message: 'Список продуктов пуст',
        }
      : null

    const contractsMap = _keyBy(contracts, 'contractId')

    return {
      error: error,
      contracts: contractsMap,
      status,
      retry: () => {
        retryFns.forEach((item) => {
          item()
        })
      },
    }
  }
)

export const useClientProductsMFOSelector = () => {
  const clientProductsMFO = useClientProductsMFOQuery({ ReturnCachedData: false })

  return selector({ clientProductsMFO })
}
