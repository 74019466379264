import React from 'react'
import { Payment } from './containers/Payment'
import { PaymentShimmer } from './components/PaymentShimmer'
import { NextPaymentShimmer } from './components/NextPaymentShimmer'
import { NextPayment } from './containers/NextPayment'
import { GetCreditLinesMFOModel } from '@/api/products/mfo/getCreditLinesMFO'

export interface CreditLineDetailsProps {
  contract: GetCreditLinesMFOModel.CreditLine
  serverDate: string
  isLoading: boolean
  refetch: () => void
}

export const CreditLineDetails = ({
  contract,
  serverDate,
  isLoading,
  refetch,
}: CreditLineDetailsProps) => {
  const isFullRepayment =
    contract.guiStatus === GetCreditLinesMFOModel.GuiStatus.NoPaymentSchedule ||
    contract.guiStatus === GetCreditLinesMFOModel.GuiStatus.FullRepayment

  const isClosed = contract.status === GetCreditLinesMFOModel.StatusCode.Closed

  if (isLoading)
    return (
      <>
        <PaymentShimmer />
        {isFullRepayment ? null : <NextPaymentShimmer />}
      </>
    )

  return (
    <>
      {!isFullRepayment && !isClosed && <Payment contract={contract} refetch={refetch} />}
      {isFullRepayment || isClosed ? null : (
        <NextPayment contract={contract} serverDate={serverDate} />
      )}
    </>
  )
}
