import React from 'react'
import {
  ShimmerWrapper,
  SliderPartnerShimmer,
  SliderBalanceShimmer,
  Wrapper,
  DescriptionShimmerWrapper,
  ItemWrapper,
  LabelShimmer,
  AmountShimmer,
} from './styled'

export const CreditLineSliderShimmer = () => {
  return (
    <ShimmerWrapper>
      <Wrapper>
        <SliderBalanceShimmer />
        <SliderPartnerShimmer />
      </Wrapper>
      <DescriptionShimmerWrapper>
        <ItemWrapper>
          <LabelShimmer />
          <AmountShimmer />
        </ItemWrapper>
        <ItemWrapper>
          <LabelShimmer />
          <AmountShimmer />
        </ItemWrapper>
      </DescriptionShimmerWrapper>
    </ShimmerWrapper>
  )
}
