import React from 'react'
import { Currency } from '@/components/Currency'
import { AboutSecurityIcon } from '@platform-ui/icons'
import {
  NextPaymentWrapper,
  NextPaymentTitleWrapper,
  NextPaymentTitle,
  DateWrapper,
  DateIconWrapper,
  DateDesc,
  DateTitle,
  PaymentDate,
  RecommendedPaymentWrapper,
} from './styled'
import { GetCreditLinesMFOModel } from '@/api/products/mfo/getCreditLinesMFO'
import { formatDate } from '@/utils/formatDate'
import { pluralize } from '@/utils'

const TITLES = ['день', 'дня', 'дней']

const toCurrentFormatDate = (date: string): string => {
  const parse = new Date(Date.parse(date))
  return formatDate(parse, 'D MMMM')
}

const getDaysBeforePayment = (date: string, serverDate: string): number => {
  const currentDate = serverDate ? new Date(serverDate) : new Date()
  const paymentDate = new Date(date)
  const timeDiff = Math.abs(currentDate.getTime() - paymentDate.getTime())
  const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24))
  return diffDays
}

const getPaymentDateByStatus = (
  guiStatus: GetCreditLinesMFOModel.GuiStatus,
  status: GetCreditLinesMFOModel.StatusCode,
  nearestDate: string,
  debtDays: number,
  serverDate: string
): string => {
  if (status === GetCreditLinesMFOModel.StatusCode.Demanded) {
    return 'Требуется полное погашение задолженности'
  }

  if (
    guiStatus === GetCreditLinesMFOModel.GuiStatus.TooEarlyToPay ||
    guiStatus === GetCreditLinesMFOModel.GuiStatus.PaymentStronglyRecommended ||
    guiStatus === GetCreditLinesMFOModel.GuiStatus.PaymentInAdvance
  ) {
    const formattedDate = toCurrentFormatDate(nearestDate)
    const daysBeforePayment = getDaysBeforePayment(nearestDate, serverDate)
    return `${formattedDate}, через ${daysBeforePayment} ${pluralize(daysBeforePayment, TITLES)}`
  }

  if (guiStatus === GetCreditLinesMFOModel.GuiStatus.PaymentDay) {
    return 'Платеж сегодня'
  }

  if (guiStatus === GetCreditLinesMFOModel.GuiStatus.OverduePayment) {
    return `Платеж просрочен на ${debtDays} ${pluralize(debtDays, TITLES)}`
  }

  return ''
}

export interface NextPaymentWidgetProps {
  nearestPayment: number
  debtDays: number
  guiStatus: GetCreditLinesMFOModel.GuiStatus
  status: GetCreditLinesMFOModel.StatusCode
  nearestDate: string
  serverDate: string
}

export const NextPaymentWidget = ({
  nearestPayment,
  debtDays,
  guiStatus,
  status,
  nearestDate,
  serverDate,
}: NextPaymentWidgetProps) => {
  const isPaymentDay = guiStatus === GetCreditLinesMFOModel.GuiStatus.PaymentDay

  const isOverduePayment =
    guiStatus === GetCreditLinesMFOModel.GuiStatus.OverduePayment ||
    status === GetCreditLinesMFOModel.StatusCode.Demanded

  const isShowAttentionIcon = isPaymentDay || isOverduePayment

  const isPaymentInAdvance = guiStatus === GetCreditLinesMFOModel.GuiStatus.PaymentInAdvance

  const bgColorForIcon = isOverduePayment ? 'icons/colors/error' : 'surface/attention'

  return (
    <>
      <NextPaymentWrapper>
        <NextPaymentTitleWrapper>
          <NextPaymentTitle color="text/main">
            {isPaymentInAdvance ? 'Спасибо за платеж' : 'Следующий платёж'}
          </NextPaymentTitle>
        </NextPaymentTitleWrapper>

        <DateWrapper>
          {isShowAttentionIcon && (
            <DateIconWrapper backgroundColor={bgColorForIcon}>
              <AboutSecurityIcon />
            </DateIconWrapper>
          )}

          <div>
            {isPaymentDay ? (
              <DateDesc color="text/main">Платёж сегодня</DateDesc>
            ) : (
              <>
                {!isShowAttentionIcon && (
                  <DateTitle>
                    {isPaymentInAdvance
                      ? 'Информация в системах Банка будет обновлена'
                      : 'Дата платежа'}
                  </DateTitle>
                )}
                <PaymentDate color="text/main">
                  {getPaymentDateByStatus(guiStatus, status, nearestDate, debtDays, serverDate)}
                </PaymentDate>
              </>
            )}
          </div>
        </DateWrapper>
      </NextPaymentWrapper>

      <RecommendedPaymentWrapper>
        <DateTitle>Рекомендованный платеж</DateTitle>
        <DateDesc color="text/main">
          <Currency amount={nearestPayment} isShowPrecisionWithNullCents />
        </DateDesc>
      </RecommendedPaymentWrapper>
    </>
  )
}
