import React from 'react'
import {
  SliderWrapper,
  Slider,
  SliderTitle,
  SliderDescription,
  InfoWrapper,
  PartnerIcon,
  SliderDescriptionItem,
  Amount,
} from './styled'
import { Currency } from '@/components/Currency'
import { observer } from 'mobx-react-lite'
import { CreditLineSliderShimmer } from './components/CreditLineSliderShimmer'
import { GetCreditLinesMFOModel } from '@/api/products/mfo/getCreditLinesMFO'

export interface CreditLineSliderProps {
  contract: GetCreditLinesMFOModel.CreditLine
  isLoading: boolean
}

const getBgColor = (
  guiStatus: GetCreditLinesMFOModel.GuiStatus,
  contractStatus: GetCreditLinesMFOModel.StatusCode
): string => {
  if (
    guiStatus === GetCreditLinesMFOModel.GuiStatus.TooEarlyToPay ||
    guiStatus === GetCreditLinesMFOModel.GuiStatus.PaymentInAdvance
  ) {
    return 'linear-gradient(0.34deg, #0082C2 0.3%, #00AAFF 145.85%)'
  }

  if (
    guiStatus === GetCreditLinesMFOModel.GuiStatus.PaymentStronglyRecommended ||
    guiStatus === GetCreditLinesMFOModel.GuiStatus.PaymentDay
  ) {
    return 'products/loanOrange'
  }

  if (
    guiStatus === GetCreditLinesMFOModel.GuiStatus.OverduePayment ||
    contractStatus === GetCreditLinesMFOModel.StatusCode.Demanded
  ) {
    return 'products/loanRed'
  }

  return 'products/undefined'
}

export const CreditLineSlider = observer(({ contract, isLoading }: CreditLineSliderProps) => {
  if (isLoading) {
    return <CreditLineSliderShimmer />
  }

  const { guiStatus, status: contractStatus, partnerInfo, balance, limit, wallet } = contract
  const bgColor = getBgColor(guiStatus, contractStatus)

  const iconUrl = partnerInfo?.logoUrl || ''

  return (
    <SliderWrapper>
      <Slider bgColor={bgColor}>
        <InfoWrapper>
          <SliderTitle>
            <Currency amount={balance} isShowPrecisionWithNullCents />
          </SliderTitle>
          {iconUrl && <PartnerIcon src={iconUrl} />}
        </InfoWrapper>
        <SliderDescription>
          <SliderDescriptionItem>
            Использовано
            <br />
            <Amount amount={(limit || 0) - (balance || 0)} isShowPrecisionWithNullCents />
          </SliderDescriptionItem>
          <SliderDescriptionItem>
            Собственные средства
            <br />
            <Amount amount={wallet} isShowPrecisionWithNullCents />
          </SliderDescriptionItem>
        </SliderDescription>
      </Slider>
    </SliderWrapper>
  )
})
